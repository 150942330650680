.alignToBottom, .fixToBottom{
    cursor: pointer;
    border: none;
    border-top: 1px solid var(--black);
    background-color: var(--neongreen);
    width: 100%;
    text-align: right;
    color: var(--black);
    position: relative;
    outline: none;
}

.alignToBottom:hover, .fixToBottom:hover{
    /* opacity: 0.75; */
    background-color: var(--lightneongreen);
}

@media (hover: none){
    .alignToBottom:hover, .fixToBottom:hover{
        background-color: var(--neongreen);
    }
}

.alignToBottom{
    position: -webkit-sticky; /* Safari */
    position: sticky;
    bottom: 0;
    text-align: left;
}

.fixToBottom{
    position: absolute;
    bottom: 0;
    text-align: right;
}

.arrowUp, .arrowDown{
    margin: auto;
    cursor: pointer;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 15px 15px 15px;
    margin: 0 5px 0 0;
    padding: 0;
    outline: none;
    /* border-color: var(--grey1) var(--grey1) var(--grey3) var(--grey1); */
}

.arrowDown{
    transform:rotate(180deg);
}

.totalVotes{
    border: none !important;   
    background-color: inherit;
    outline: none;
    margin: 0 5px 0 0;
}

@media screen and (max-width: 600px) {
    .arrowUp, .arrowDown, .totalVotes{
        margin: 5px 10px 0 0;
    }
}

.nextArrow, .backArrow{
    /* width: 100%; */
    width: 50%;
    cursor: pointer;
    margin-top: 5px;
    text-align: right;
    border: none;
    outline: none;
    background-color: inherit;    
    background-position: right;
}

.nextArrow{
    float: right;
}

.backArrow{
    text-align: left;
    bottom: 0;
    position: absolute;
}

.nextArrow:hover, .backArrow:hover{
    opacity: 0.5;
}

.disabled{
    opacity: 0.35;
}

.disabled:hover{
    opacity: 0.35 !important;
}

.arrowNext, .arrowBack{
    float: right;
    cursor: pointer;
    width: 1.3em;
    height: 0.9em;
    margin: 0.14em 5px 0 5px;
    background-position: right;
    background-image: url('../../../assets/arrow2.svg');
    background-repeat: no-repeat;
    background-size: contain;
}

.arrowBack{
    transform:rotate(180deg);
    float: left;
    margin: 0.14em 5px 0 5px;
}

.nextArrow .arrowNext{
    margin-right: 0px;
} 

.backArrow .arrowBack{
    margin-left: 0px;
}
