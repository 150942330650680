.translatedPoem {
    flex: 0 1 16.5%;
    overflow-x: hidden;
    cursor: pointer;
}

.translation * {
    display: none;
}

.translation h1 {
    display: block;
}

.selectedLanguage {
    /* flex: 1 1 auto; */
    /* flex: 1 0 50%; */
    flex: 1 0 18em;
    display: 1 !important;
}

/* @media screen and (max-height: 49em){
    .selectedLanguage {
        flex: 1 0 24em;
    }
} */

.selectedLanguage * {
    display: block;
}

.collapsedLanguage {
    flex: 0 0 5%;
}

.collapsedLanguage * {
    display: none;
}

.collapsedLanguage h1 {
    display: block;
}

/* set container to 100% to align button to bottom */
.translation{
    box-shadow: -3px 0px 10px 0px rgba(0,0,0,0.2);
    height: 100%;
    position: relative;
    box-sizing: border-box;
}

.allTranslations{
    min-height: 100%;
    height: auto;
}

.communityTranslation, .originalTranslation{
    padding: 5px;
    box-sizing: border-box;
    border-top: 1px solid var(--black);
}

.de{
    background-color: var(--grey1);
}

.en{
    background-color: var(--grey2);
}

.fr{
    background-color: var(--grey3);
}

.it{
    background-color: var(--grey4);
}

/* 
.starImage{
    width: 30px;
    height: 30px;
    background-image: url('../../assets/star-black.svg');
    background-image: url('../../assets/star.svg');
    background-size: contain;
    background-repeat: no-repeat;

    position: absolute;
    right: 5px;
} */