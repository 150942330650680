.overlay{
    z-index: 100;
    position: fixed;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    background-image: url('./assets/overlay.png');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    pointer-events: none;
    opacity: 1; 
    transition: opacity 0.3s ease-in-out;
    /* IE11 */
    /* filter:progid:DXImageTransform.Microsoft.AlphaImageLoader(src='./assets/overlay.png', sizingMethod='scale');
    background: none !important; */
}

.container{
    display: flex;
    flex-flow: column;
    width: 100vw;

    box-sizing: border-box;
}

@media screen and (min-width: 600px) {
    .container{
        flex-flow: row;
    }
}