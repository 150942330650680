/* TEXTAREAS */
textarea {
    border-radius: 0;
    outline: none;
    resize: none;
    white-space: nowrap;
    border: none;
    border-bottom: 1px solid var(--black);
    background-color: rgba(0, 0, 0, 0);
    width: 100%;
    padding: 0;
    box-sizing: border-box;
    line-height: 1. !important;
    -webkit-appearance: none;
}

/* diff. colors for diff. backgrounds */
textarea::placeholder{
    color: var(--grey2);  
}

#en textarea::placeholder{
    color: var(--grey3);  
}

#fr textarea::placeholder{
    color: var(--grey4);  
}

#it textarea::placeholder{
    color: var(--grey5);  
}

.submitting {
    color: var(--grey3);  
}

#en .submitting {
    color: var(--grey4);  
}

#fr .submitting {
    color: var(--grey5);  
}

#it .submitting {
    color: var(--black);  
}

/* paddng on parent */
#newTranslation{
    padding-left: 5px; 
    padding-right: 10px;
}

.poemLine {
    font-family: sans-serif;
    font-size: 3.8vw;
    line-height: 1.2;
}

@media screen and (max-width: 600px) {
    .poemLine {
        font-size: 8.6vw;
    }
    #newTranslation{
        padding-left: 5px; 
        padding-right: 5px;
    }
    .author{
        position: relative !important;
    }
    .backLink {
        display: none;
    }
  }

@media screen and (min-width: 1050px) {
    .poemLine {
        font-size: 40px;
    }
  }

.poemLine1{
    font-weight: bold;
    line-height: 1.2 !important;
}

.author {
    position: absolute;
    bottom: 0;
    padding: 5px;
}

.author > a{
    color: var(--neongreen);
}

.translator > a {
    /* color: inherit; */
    color: var(--neongreen);
}

.translator > a:hover, .author > a:hover {
    color: var(--grey2)
}

.username{
    font-style: italic;
}
  

.originalTranslation{
    background-image: url('../assets/translationoverlay2.png');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    /* pointer-events: none; */
    /* background-color: var(--neongreen); */
    /* background-color: white; */
    /* color: var(--neongreen); */
    /* color: white; */
}

#fr .originalTranslation, #it .originalTranslation{
    background-image: url('../assets/translationoverlay1.png') !important;
}

#de .originalTranslation{
    background-image: url('../assets/translationoverlay3.png') !important;
}

