/* colors */
:root {
    --lightneongreen: #76ffa5;
    --neongreen: #3BFF7E;
    --grey1: #DBDBDB;
    --grey2: #B3B3B3;
    --grey3: #8A8A8A;
    --grey4: #5A5A5A;
    --grey5: #2A2A2A;
    --black: #000000;
}