html, body{
    font-family: sans-serif;
    margin: 0;
    padding: 0;
    height: 100%;
    touch-action: manipulation;
}

::-moz-selection { /* Code for Firefox */
    background: var(--neongreen);
}

::selection {
    background: var(--neongreen);
}


div{
    /* border: 1px solid black; */
}

/* SERIF FONT */
@font-face{
    font-family: 'AT Apoc Revelations';
    src: url('../assets/Apoc-Revelations.woff') format('woff'),
         url('../assets/Apoc-Revelations.woff2') format('woff2'),
         url('../assets/Apoc-Revelations.otf') format('opentype'),
         url('../assets/Apoc-Revelations.eot') format('embedded-opentype');
}

.serif {
    font-family: 'AT Apoc Revelations' !important;
}

/* LANGUAGE TITLES */
h1{
    text-transform: uppercase;
    font-size: 52px;
    font-weight: normal;
    margin: 0px 0px 5px 0px;
    padding-left: 5px;
    line-height: 1.1;

    position: -webkit-sticky; /* Safari */
    position: sticky;
    top: 0;
    background-color: inherit;
}

button{
    user-select: none;
    padding: 0;
    /* mobile: removes grey highlight on click*/
    -webkit-tap-highlight-color: rgba(0,0,0,0);
}

/* VOTE BUTTON */
#de > div > div > div > button, #de > div > div > div > .disabled:hover{
    border-color: var(--grey1) var(--grey1) var(--grey3) var(--grey1);
}

#en > div > div > div > button, #en > div > div > div > .disabled:hover{
    border-color: var(--grey2) var(--grey2) var(--grey4) var(--grey2);
}

#fr > div > div > div > button, #fr > div > div > div > .disabled:hover{
    border-color: var(--grey3) var(--grey3) var(--grey5) var(--grey3);
}

#it > div > div > div > button, #it > div > div > div > .disabled:hover{
    border-color: var(--grey4) var(--grey4) var(--black) var(--grey4);
}

/* VOTE BUTTON HOVER */
#de > div > div > div > button:hover{
    border-color: var(--grey1) var(--grey1) var(--neongreen) var(--grey1);
}

#en > div > div > div > button:hover{
    border-color: var(--grey2) var(--grey2) var(--neongreen) var(--grey2);
}

#fr > div > div > div > button:hover{
    border-color: var(--grey3) var(--grey3) var(--neongreen) var(--grey3);
}

#it > div > div > div > button:hover{
    border-color: var(--grey4) var(--grey4) var(--neongreen) var(--grey4);
}
/* TRANSLATOR / USERNAME/ DATE COLOR */
#de .translator, #de .username, #de .date{
    color: var(--grey3);
}

#en .translator, #en .username, #en .date{
    color: var(--grey4);
}

#fr .translator, #fr .username, #fr .date{
    color: var(--grey5);
}
#it .translator, #it .username, #it .date{
    color: var(--grey5);
}

@media (hover: none) {
    /* VOTE BUTTON HOVER */
    #de > div > div > div > button:hover{
        border-color: var(--grey1) var(--grey1) var(--grey3) var(--grey1);
    }

    #en > div > div > div > button:hover{
        border-color: var(--grey2) var(--grey2) var(--grey4) var(--grey2);
    }

    #fr > div > div > div > button:hover{
        border-color: var(--grey3) var(--grey3) var(--grey5) var(--grey3);
    }

    #it > div > div > div > button:hover{
        border-color: var(--grey4) var(--grey4) var(--black) var(--grey4);
    }  
}



.voteButtonContainer {
    position: absolute;
    display: flex;
    flex-flow: column;
    right: 5px;
}